<template>
  <header
    class="navbar navbar-expand-lg navbar-light bg-light"
    :class="{ 'header-hidden': !showHeader }"
  >
    <div class="container-fluid">
      <a href="/" class="navbar-brand"> <img src="@/assets/logo.svg" /> </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleMenu"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        :class="{ show: isMenuOpen }"
        id="navbarNav"
      >
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a href="/#home" class="nav-link" @click="closeMenu">Home</a>
          </li>
          <li class="nav-item">
            <a href="/#services" class="nav-link" @click="closeMenu"
              >Services</a
            >
          </li>
          <li class="nav-item">
            <a href="/#about" class="nav-link" @click="closeMenu">About</a>
          </li>
          <li class="nav-item">
            <a href="/#contact" class="nav-link" @click="closeMenu">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "MainHeader",
  data() {
    return {
      lastScrollTop: 0,
      showHeader: true,
      isMenuOpen: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > this.lastScrollTop && st > 100) {
        this.showHeader = false;
      } else {
        this.showHeader = true;
      }
      this.lastScrollTop = st <= 0 ? 0 : st;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
    closeMenu() {
      this.isMenuOpen = false;
      document.body.classList.remove("overflow-hidden");
    },
  },
  beforeRouteUpdate(to, from, next) {
    window.scrollTo(0, 0);
    next();
  },
};
</script>

<style scoped>
header {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #ffffff !important;
}

.navbar img {
  height: 80px;
}

.header-hidden {
  transform: translateY(-100%);
  opacity: 0;
}

.navbar-toggler {
  border-color: transparent;
  outline: none;
  padding: 0.5rem;
}
/* Adjust dropdown direction */
.dropdown-menu-end {
  right: 0;
  left: auto;
}

/* Make all nav links dark */
.navbar-light .navbar-nav .nav-link {
  color: black;
  padding: 0.5rem 1rem;
}

/* Improve mobile menu links */
@media (max-width: 991.98px) {
  .navbar-collapse {
    padding: 1rem;
  }

  .navbar-nav .nav-item {
    margin-bottom: 1rem;
  }

  .navbar-nav .nav-link {
    color: black;
    font-size: 1.2rem;
    padding: 0.5rem 0;
    display: block;
    border-bottom: 1px solid #ccc;
  }

  .navbar-nav .nav-link:hover {
    color: #555;
  }
}

/* Class to disable body scrolling */
.overflow-hidden {
  overflow: hidden;
}
</style>
