<template>
  <div class="gallery-section" id="gallery">
    <div class="gallery-content container">
      <h1
        class="gallery-title wow animate__animated animate__fadeInDown"
        data-wow-delay="0.1s"
      >
        Our Gallery
      </h1>
      <div class="gallery-grid">
        <div
          v-for="(image, index) in images"
          :key="index"
          class="gallery-item wow animate__animated animate__fadeIn"
          :data-wow-delay="`${0.2 + index * 0.1}s`"
          @click="openModal(image)"
        >
          <img
            :src="require(`@/assets/Home/gallery/${image}`)"
            :alt="`Image ${index + 1}`"
            class="gallery-photo"
          />
        </div>
      </div>
      <div class="modal" v-if="isModalOpen" @click.self="closeModal">
        <div class="modal-content fade-in">
          <button class="modal-close" @click="closeModal">&times;</button>
          <img
            :src="require(`@/assets/Home/gallery/${currentImage}`)"
            :alt="`Image ${images.indexOf(currentImage) + 1}`"
            class="modal-photo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";

export default {
  name: "GallerySection",
  data() {
    return {
      images: [
        "3.jpg",
        "4.jpg",
        "5.jpg",
        "6.jpg",
        "7.jpg",
        "9.jpg",
        "10.jpg",
        "11.jpg",
        "12.jpg",
      ],
      isModalOpen: false,
      currentImage: "",
    };
  },
  methods: {
    openModal(image) {
      this.currentImage = image;
      this.isModalOpen = true;
      document.body.style.overflow = "hidden"; // Disable scrolling when modal is open

      this.$nextTick(() => {
        const modal = document.querySelector(".modal");
        if (modal) {
          modal.classList.add("fade-in");
        }
      });

      document.addEventListener("keydown", this.handleEscapeKey); // Add event listener for Escape key
    },
    closeModal() {
      const modal = document.querySelector(".modal");
      if (modal) {
        modal.classList.remove("fade-in");
      }

      setTimeout(() => {
        this.isModalOpen = false;
        this.currentImage = "";
        document.body.style.overflow = "auto"; // Re-enable scrolling
      }, 300);

      document.removeEventListener("keydown", this.handleEscapeKey); // Remove event listener
    },
    handleEscapeKey(event) {
      if (event.key === "Escape") {
        this.closeModal();
      }
    },
  },
  mounted() {
    new WOW().init();
  },
};
</script>

<style lang="scss" scoped>
.gallery-section {
  background-color: #f0f4f8;
  padding: 80px 20px;

  .gallery-content {
    text-align: center;

    .gallery-title {
      font-size: 3rem;
      margin-bottom: 40px;
      color: #35625c;
      font-family: "Helvetica Neue", Arial, sans-serif;

      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
    }

    .gallery-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* 3 фотографии в ряду */
      gap: 20px;

      @media (max-width: 768px) {
        grid-template-columns: repeat(
          2,
          1fr
        ); /* На мобильных устройствах 2 фотографии в ряду */
      }

      @media (max-width: 480px) {
        grid-template-columns: 1fr; /* На очень маленьких экранах 1 фотография в ряду */
      }

      .gallery-item {
        position: relative;
        overflow: hidden;
        border-radius: 12px;
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
        cursor: pointer;
        padding-top: 100%; /* Соотношение 1:1 */

        .gallery-photo {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; /* Изображение будет масштабироваться с сохранением пропорций */
          transition: transform 0.3s ease, box-shadow 0.3s ease;

          &:hover {
            transform: scale(1.05);
            box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;

    &.fade-in {
      opacity: 1;
      visibility: visible;
    }

    .modal-content {
      position: relative;
      max-width: 95%;
      max-height: 95%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border-radius: 12px;
      overflow: hidden;
      transform: scale(0.9);
      transition: transform 0.3s ease;

      &.fade-in {
        transform: scale(1);
      }

      .modal-photo {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        display: block;
      }

      .modal-close {
        position: absolute;

        top: 15px;
        right: 15px;
        width: 40px;
        height: 40px;
        font-size: 25px;
        font-weight: bold;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
        background: #35625c;
        border: none;
        z-index: 1001;
      }
    }
  }
}
</style>
