<template>
  <div class="hero-section" id="home">
    <div class="hero-content">
      <div class="hero-text">
        <h1
          class="site-title wow animate__animated animate__fadeInDown"
          data-wow-delay="0.1s"
        >
          Luxury Redefined
        </h1>
        <h2
          class="hero-title wow animate__animated animate__fadeInDown"
          data-wow-delay="0.2s"
        >
          Tailored Event Planning with a Personal Touch
        </h2>
        <p
          class="hero-description wow animate__animated animate__fadeInDown"
          data-wow-delay="0.4s"
        >
          Style by MG Events brings accessible luxury to life, turning each
          client's style and vision into unique and unforgettable events. We
          handle every step, from the first call to the event day, ensuring your
          celebration is a stress-free reflection of your dream.
        </p>
        <a
          href="#about"
          class="hero-button wow animate__animated animate__fadeInUp"
          data-wow-delay="0.6s"
        >
          Learn More
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";

export default {
  name: "HeroSection",
  mounted() {
    new WOW().init();
  },
};
</script>

<style lang="scss" scoped>
.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: url("@/assets/Home/123.png") no-repeat center;
  background-size: cover;
  padding: 40px 20px;
  color: #ffffff;

  @media (max-width: 1000px) {
    .hero-section {
      background: url("@/assets/Home/123home.png") no-repeat;
      background-size: cover;
    }
  }
  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Выравнивание по левому краю */
    max-width: 1200px;
    width: 100%;
  }

  .hero-text {
    max-width: 700px;
    margin-bottom: 40px;
    text-align: left; /* Выравнивание текста по левому краю */

    .site-title {
      font-size: 2.5rem;
      margin-bottom: 20px;
      color: #ffffff;

      @media (max-width: 768px) {
        font-size: 2rem;
      }

      @media (max-width: 576px) {
        font-size: 1.75rem;
      }
    }

    .hero-title {
      font-size: 1.5rem;
      margin-bottom: 15px;
      color: #ffffff;

      @media (max-width: 768px) {
        font-size: 1.25rem;
      }

      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }

    .hero-description {
      font-size: 1.125rem;
      margin-bottom: 25px;
      line-height: 1.6;
      color: #ffffff;

      @media (max-width: 768px) {
        font-size: 1rem;
      }

      @media (max-width: 576px) {
        font-size: 0.875rem;
      }
    }

    .hero-button {
      padding: 15px 35px;
      font-size: 1.125rem;
      color: #35625c;
      background-color: #ffffff;
      border-radius: 30px;
      text-transform: uppercase;
      letter-spacing: 1px;
      transition: background-color 0.3s ease, transform 0.3s ease;
      text-decoration: none;

      &:hover {
        background-color: #f2f2f2;
        transform: translateY(-5px);
      }
    }
  }

  @media (max-width: 768px) {
    .hero-content {
      align-items: flex-start;
    }
  }

  @media (max-width: 576px) {
    .hero-section {
      padding: 20px 10px;
    }
  }
}
</style>
