<template>
  <section class="contact-section" id="contact">
    <div class="container">
      <h1 class="contact-title">Get in Touch</h1>
      <p class="contact-intro">
        We would love to hear from you! Whether you have questions, need more
        information, or want to discuss your upcoming event, feel free to reach
        out to us. We're here to help and ensure you have all the details you
        need.
      </p>
      <div
        class="contact-info wow animate__animated animate__fadeInUp"
        data-wow-delay="0.1s"
      >
        <div class="contact-item">
          <i class="bi bi-envelope contact-icon"></i>
          <a href="mailto:stylebymg@yahoo.com" class="contact-link"
            >stylebymg@yahoo.com</a
          >
        </div>
      </div>
      <p class="contact-note">
        Please include your name and details about your event in your email, and
        we will get back to you as soon as possible.
      </p>
    </div>
  </section>
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";

export default {
  name: "ContactSection",
  mounted() {
    new WOW().init();
  },
};
</script>

<style lang="scss" scoped>
.contact-section {
  padding: 60px 20px;
  color: #333;

  .container {
    text-align: center;

    .contact-title {
      font-size: 2.8rem;
      color: #35625c;
      margin-bottom: 20px;
      font-weight: 700;
    }

    .contact-intro {
      font-size: 1.2rem;
      color: #555;
      margin-bottom: 30px;
      line-height: 1.6;
    }

    .contact-info {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 20px;

      .contact-item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-size: 1.25rem;

        .contact-icon {
          font-size: 2.5rem;
          color: #35625c;
        }

        .contact-link {
          color: #35625c;
          text-decoration: none;
          font-weight: bold;
          transition: color 0.3s, text-shadow 0.3s;

          &:hover {
            color: #2d4d44;
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }

    .contact-note {
      font-size: 1rem;
      color: #777;
      margin-top: 30px;
      line-height: 1.6;
    }
  }
}
</style>
