<template>
  <footer id="footer" class="footer-2">
    <div class="main-footer">
      <div class="container">
        <div class="row">
          <!-- Brand Section -->
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="widget subscribe no-box">
              <h5 class="widget-title">Style by MG Events</h5>
              <p>
                Tailored Event Planning with a Personal Touch. We turn your
                style and vision into unforgettable events.
              </p>
            </div>
          </div>

          <!-- Quick Links Section -->
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="widget no-box">
              <h5 class="widget-title">Quick Links</h5>
              <ul class="thumbnail-widget">
                <li>
                  <div class="thumb-content">
                    <a href="/#home" class="nav-link">Home</a>
                  </div>
                </li>
                <li>
                  <div class="thumb-content">
                    <a href="/#services" class="nav-link">Services</a>
                  </div>
                </li>
                <li>
                  <div class="thumb-content">
                    <a href="/#about" class="nav-link">About</a>
                  </div>
                </li>
                <li>
                  <div class="thumb-content">
                    <a href="/#contact" class="nav-link">Contact</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- Contact Section -->
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="widget no-box">
              <h5 class="widget-title">Contact Us</h5>
              <ul class="thumbnail-widget">
                <li>
                  <div class="thumb-content">stylebymg@yahoo.com</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterS",
};
</script>

<style scoped>
/* Footer styles */
#footer {
  border-top: 1px solid #35625c;
  display: flex;
  flex-direction: column;
}

.main-footer {
  background-color: #ffffff;
  color: #333;
  padding: 60px 0;
  flex: 1;
}

.widget-title {
  color: #35625c;
  font-size: 18px;
  margin-bottom: 30px;
  border-bottom: 1px solid #35625c;
  padding-bottom: 10px;
  position: relative;
  display: inline-block;
}

.widget-title span {
  background-color: #35625c;
  display: block;
  height: 1px;
  width: 50px;
  margin-top: 10px;
  bottom: 0;
  left: 0;
}

.widget p {
  color: #333;
}

.thumbnail-widget {
  list-style: none;
  padding: 0;
}

.thumb-content {
  margin-bottom: 10px;
}

.thumb-content a {
  color: #35625c;
  text-decoration: none;
  transition: color 0.3s;
}

.thumb-content a:hover {
  color: #2d4d44;
}

@media (max-width: 767px) {
  .main-footer .col-sm-6 {
    margin-bottom: 30px;
  }
}
</style>
