<template>
  <div class="services-section" id="services">
    <div class="services-content container">
      <h1 class="services-title">Our Services</h1>
      <div class="services-list">
        <!-- Kids Birthday Parties -->
        <div
          class="service-item wow animate__animated animate__fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="service-icon-container">
            <i class="bi bi-gift service-icon"></i>
          </div>
          <div class="service-text">
            <h2 class="service-title">Kids Birthday Parties</h2>
            <p class="service-description">
              We create unforgettable birthday parties with exciting themes and
              activities, ensuring a day full of fun and joy for your kids.
            </p>
          </div>
        </div>

        <!-- Weddings -->
        <div
          class="service-item wow animate__animated animate__fadeInUp"
          data-wow-delay="0.2s"
        >
          <div class="service-icon-container">
            <i class="bi bi-heart service-icon"></i>
          </div>
          <div class="service-text">
            <h2 class="service-title">Weddings</h2>
            <p class="service-description">
              Crafting personalized weddings that reflect your unique love
              story, we make sure your special day is beautifully memorable.
            </p>
          </div>
        </div>

        <!-- Corporate and Social Events -->
        <div
          class="service-item wow animate__animated animate__fadeInUp"
          data-wow-delay="0.3s"
        >
          <div class="service-icon-container">
            <i class="bi bi-briefcase service-icon"></i>
          </div>
          <div class="service-text">
            <h2 class="service-title">Corporate and Social Events</h2>
            <p class="service-description">
              From business events to social gatherings, we manage all aspects
              to ensure your event is seamless and impactful.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";

export default {
  name: "ServicesSection",
  mounted() {
    new WOW().init();
  },
};
</script>

<style lang="scss" scoped>
.services-section {
  padding: 60px 20px;
  color: #333;

  .services-content {
    text-align: center;

    .services-title {
      font-size: 2.5rem;
      color: #35625c;
      margin-bottom: 40px;
      font-weight: 700;
    }

    .services-list {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 20px;

      .service-item {
        background: #ffffff;
        border-radius: 12px;
        padding: 20px;
        width: 280px;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        position: relative;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
        }

        .service-icon-container {
          position: absolute;
          top: -30px;
          left: 50%;
          transform: translateX(-50%);
          width: 80px;
          height: 80px;
          background-color: #35625c;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 3rem;
        }

        .service-text {
          margin-top: 50px;

          .service-title {
            font-size: 1.5rem;
            color: #35625c;
            margin-bottom: 10px;
            font-weight: 600;
          }

          .service-description {
            font-size: 1rem;
            color: #555;
            line-height: 1.6;
          }
        }
      }
    }
  }
}
</style>
