<template>
  <div class="about-section" id="about">
    <div class="about-content container">
      <!-- Introduction Block -->
      <div class="about-block intro">
        <div
          class="about-text-container wow animate__animated animate__fadeIn"
          data-wow-delay="0.1s"
        >
          <h1 class="about-title">About Us</h1>
          <p class="about-description">
            At Style by MG Events, our philosophy is to deliver affordable
            luxury design while forming personal relationships with our clients.
            We focus on every detail and authentic experience to create
            one-of-a-kind events tailored to each client’s unique needs.
          </p>
        </div>
        <div
          class="about-image-container wow animate__animated animate__fadeIn"
          data-wow-delay="0.2s"
        >
          <img
            src="@/assets/Home/about.jpg"
            alt="About Us"
            class="about-photo"
          />
        </div>
      </div>

      <!-- Process Block -->
      <div class="about-block">
        <div
          class="about-image-container wow animate__animated animate__fadeIn"
          data-wow-delay="0.3s"
        >
          <img
            src="@/assets/Home/gallery/1.jpg"
            alt="Design Process"
            class="about-photo"
          />
        </div>
        <div
          class="about-text-container wow animate__animated animate__fadeIn"
          data-wow-delay="0.4s"
        >
          <h2 class="about-subtitle">Our Design Process</h2>
          <p class="about-description">
            We guide every client through a comprehensive design process to
            ensure their style and imagination come to life. Each celebration
            deserves a personal touch, and we’re dedicated to making that
            happen.
          </p>
        </div>
      </div>

      <!-- Mission Block -->
      <div class="about-block mission">
        <div
          class="about-text-container wow animate__animated animate__fadeIn"
          data-wow-delay="0.5s"
        >
          <h2 class="about-subtitle">Our Mission</h2>
          <p class="about-description">
            Our goal is to bring your vision of style to life, whether it’s a
            wedding, kids' birthday party, corporate event, bar/bat mitzvah,
            outdoor event, non-profit gala, holiday party, or more. From our
            first call to the day of your event, we ensure a 100% personalized
            experience to meet your unique needs and alleviate stress.
          </p>
        </div>
        <div
          class="about-image-container wow animate__animated animate__fadeIn"
          data-wow-delay="0.6s"
        >
          <img
            src="@/assets/Home/gallery/2.jpg"
            alt="Mission"
            class="about-photo"
          />
        </div>
      </div>

      <!-- Contact Button Block -->
      <div class="contact-block">
        <a
          href="#contact"
          class="about-button wow animate__animated animate__fadeIn"
          data-wow-delay="0.7s"
        >
          Let's Get in Touch
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";

export default {
  name: "AboutSection",
  mounted() {
    new WOW().init();
  },
};
</script>

<style lang="scss" scoped>
.about-section {
  padding: 80px 20px;
  color: #333;

  .about-content {
    display: flex;
    flex-direction: column;
    gap: 60px;

    @media (max-width: 768px) {
      text-align: center;
    }

    .about-block {
      display: flex;
      align-items: center;
      gap: 30px;
      padding: 20px;
      border-bottom: 1px solid #ddd;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      &.intro {
        border-top: 1px solid #ddd;
      }

      &.mission {
        border-bottom: 1px solid #ddd;
      }

      .about-text-container {
        flex: 1;

        .about-title {
          font-size: 3rem;
          color: #35625c;
          margin-bottom: 15px;
          font-weight: 700;
          line-height: 1.2;

          @media (max-width: 768px) {
            font-size: 2.5rem;
          }
        }

        .about-subtitle {
          font-size: 2rem;
          color: #35625c;
          margin-bottom: 10px;
          font-weight: 600;

          @media (max-width: 768px) {
            font-size: 1.75rem;
          }
        }

        .about-description {
          font-size: 1.25rem;
          color: #555;
          margin-bottom: 25px;
          line-height: 1.7;

          @media (max-width: 768px) {
            font-size: 1.1rem;
          }
        }
      }

      .about-image-container {
        flex: 1;
        display: flex;
        justify-content: center;

        .about-photo {
          width: 100%;
          max-width: 500px;
          border-radius: 15px;
        }
      }
    }

    .contact-block {
      text-align: center;

      .about-button {
        padding: 16px 60px;
        font-size: 1.5rem;
        background-color: #35625c;
        color: #ffffff;
        border-radius: 30px;
        transition: background-color 0.3s ease, transform 0.3s ease;
        text-decoration: none;

        &:hover {
          background-color: #2a4e44;
          transform: translateY(-3px);
        }
      }
    }
  }
}
</style>
