<template>
  <div>
    <transition name="fade">
      <div v-if="isLoading" class="preloader">
        <div class="loader"></div>
      </div>
    </transition>

    <Header v-if="!isLoading"></Header>
    <router-view v-if="!isLoading" />

    <Footer v-if="!isLoading"></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
};
</script>

<style>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 40px;
  aspect-ratio: 1;
  --c: linear-gradient(#35625c 0 0);
  --m: radial-gradient(farthest-side, #35625c 92%, #f25c2d00);
  background: var(--m) center / 12px 12px,
    var(--c) left 50% top -20px / 8px 16px,
    var(--c) left 50% bottom -20px / 8px 16px,
    var(--c) top 50% left -20px / 16px 8px,
    var(--c) top 50% right -20px / 16px 8px;
  background-repeat: no-repeat;
  animation: l18-1 1.5s infinite, l18-2 1.5s infinite;
}

@keyframes l18-1 {
  30%,
  70% {
    background-position: center, left 50% top calc(50% - 8px),
      left 50% bottom calc(50% - 8px), top 50% left calc(50% - 8px),
      top 50% right calc(50% - 8px);
  }
}

@keyframes l18-2 {
  0%,
  40% {
    transform: rotate(0);
  }
  60%,
  100% {
    transform: rotate(90deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
