<template>
  <Main />
  <Services />
  <AboutUs />
  <GallerySection />
  <Contacts />
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Ensure Bootstrap's JavaScript is imported

import Contacts from "@/components/Contacts.vue";
import Services from "@/components/Services.vue";

import Main from "@/components/Main.vue";
import AboutUs from "@/components/AboutUs.vue";
import GallerySection from "@/components/GallerySection.vue";

export default {
  name: "HomeView",
  components: {
    Contacts,
    AboutUs,
    Main,
    GallerySection,
    Services,
  },
  mounted() {
    new WOW().init();
  },
};
</script>
